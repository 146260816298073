import request from '@/utils/request'
export const courseIndex = (data) => request({
  url: '/api/course/index',
  method: 'POST',
  data
});
// 课程详情
export const getCourseDetail = (data) => request({
  url: '/api/course/info',
  method: 'POST',
  data
});
// 直播首页
export const getLiveList = (data) => request({
  url: '/api/live/index',
  method: 'POST',
  data
});
// 直播详情
export const liveInfo = (data) => request({
  url: '/api/live/info',
  method: 'POST',
  data
});
// 立即预约
export const appointment = (data) => request({
  url: '/api/live/appointment',
  method: 'POST',
  data
});
// 系列课
export const series = (data) => request({
  url: '/api/course/series',
  method: 'POST',
  data
});
// 视频播放
export const videoDetail = (data) => request({
  url: '/api/course/culum_detail',
  method: 'POST',
  data
});
// 获取播放地址
export const videoUrl = (data) => request({
  url: '/api/course/video_detail',
  method: 'POST',
  data
});
// 视频结束
export const vedioFinish = (data) => request({
  url: '/api/course/studied',
  method: 'POST',
  data
});
// 视频播放进度
export const videoWatch = (data) => request({
  url: '/api/course/video_watch',
  method: 'POST',
  data
});
// 获取笔记
export const NoteList = (data) => request({
  url: '/api/course/getNotes',
  method: 'POST',
  data
});
// 新增笔记
export const addNote = (data) => request({
  url: '/api/course/addNotes',
  method: 'POST',
  data
});
// 删除笔记
export const delNote = (data) => request({
  url: '/api/course/delNotes',
  method: 'POST',
  data
});
// 课程推荐
export const recommend = (data) => request({
  url: '/api/course/recommend',
  method: 'POST',
  data
});
// 课程评论
export const commentList = (data) => request({
  url: '/api/course/comment',
  method: 'POST',
  data
});
// 新增评论
export const addComment = (data) => request({
  url: '/api/course/add_comment',
  method: 'POST',
  data
});
// 直播视频
export const liveVideo = (data) => request({
  url: '/api/live/video_detail',
  method: 'POST',
  data
});